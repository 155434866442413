import React, {Component} from 'react';
import Chart from 'chart.js';
import logo from './james.svg';
import './App.css';

const apiRoot = process.env.REACT_APP_APIROOT
const apiKey = process.env.REACT_APP_APIKEY
class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      days: [],
      drinksChart: null,
      exerciseChart: null,
      runningChart: null,
      weightChart: null
    } 

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(e) {
    e.preventDefault();
    if(this.state.drinksChart) { this.getData('Drinks', true, this.populateDrinksGraph) }
    if(this.state.exerciseChart) { this.getData('Exercise', true, this.populateExerciseGraph) }  
    if(this.state.runningChart) { this.getData('Running', true, this.populateRunningGraph) }  
    if(this.state.weightChart) { this.getData('Weight', true, this.populateWeightGraph) }  
  }

  getData(serviceName, calcStats, next) {
    fetch(`${apiRoot}/${serviceName}?stats=${ calcStats ? 1 : 0 }`, { headers: { "x-api-key": apiKey}})
    .then(res => { return res.json() })
    .then((data) => {
      //console.debug(data)
      next(data)
    })    
    .catch(console.log)
  }

  //https://www.chartjs.org/docs/latest/charts/line.html
  populateDrinksGraph(data) {
    var chartData = data.map((datum) => { return { x: datum.date, y: datum.drinkCount } }).slice(0, 90).sort()
    var averageData = data.map((datum) => { return { x: datum.date, y: datum.drinkCountAverage } }).slice(0, 90).sort()

    var chartElement = document.getElementById('drinksCanvas')
    new Chart(chartElement, {
      type: 'bar',
      data: {
        datasets: [{
          label: 'Dranks',
          data: chartData,
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          borderColor: 'rgba(54, 162, 235, 1)',
          borderWidth: 1
        },
        {
          label: 'Average',
          data: averageData,
          backgroundColor: 'rgba(255, 99, 132, 0.2)',
          borderColor: 'rgba(255, 99, 132, 1)',
          borderWidth: 1,
          type: 'line'
        }]
      },
      options: {
          scales: {
              xAxes: [{
                type: 'time',
                time: { unit: 'day' },
                ticks: { source: 'auto' }
              }],
              yAxes: [{
                  ticks: {
                      beginAtZero: true
                  }
              }]
          }
      }
    })
  }

  populateExerciseGraph(data) {
    var pullupsData = data.map((datum) => { return { x: datum.date, y: datum.pullups } }).slice(0, 30).sort()
    var pullupsAverageData = data.map((datum) => { return { x: datum.date, y: datum.pullupsAverage } }).slice(0, 30).sort()
    var pushupsData = data.map((datum) => { return { x: datum.date, y: datum.pushups } }).slice(0, 30).sort()
    var pushupsAverageData = data.map((datum) => { return { x: datum.date, y: datum.pushupsAverage } }).slice(0, 30).sort()
    var situpsData = data.map((datum) => { return { x: datum.date, y: datum.situps } }).slice(0, 30).sort()
    var situpsAverageData = data.map((datum) => { return { x: datum.date, y: datum.situpsAverage } }).slice(0, 30).sort()
    var squatsData = data.map((datum) => { return { x: datum.date, y: datum.squats } }).slice(0, 30).sort()
    var squatsAverageData = data.map((datum) => { return { x: datum.date, y: datum.squatsAverage } }).slice(0, 30).sort()

    var chartElement = document.getElementById('exerciseCanvas')
    new Chart(chartElement, {
      type: 'bar',
      data: {
        datasets: [{
          label: 'Pullups',
          data: pullupsData,
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          borderColor: 'rgba(54, 162, 235, 1)',
          borderWidth: 1
        },
        {
          label: 'Average Pullups',
          data: pullupsAverageData,
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          borderColor: 'rgba(54, 162, 235, 1)',
          borderWidth: 1,
          type: 'line'
        },
        {
          label: 'Pushups',
          data: pushupsData,
          backgroundColor: 'rgba(255, 99, 132, 0.2)',
          borderColor: 'rgba(255, 99, 132, 1)',
          borderWidth: 1
        },
        {
          label: 'Average Pushups',
          data: pushupsAverageData,
          backgroundColor: 'rgba(255, 99, 132, 0.2)',
          borderColor: 'rgba(255, 99, 132, 1)',
          borderWidth: 1,
          type: 'line'
        },
        {
          label: 'Situps',
          data: situpsData,
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1
        },
        {
          label: 'Average Situps',
          data: situpsAverageData,
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1,
          type: 'line'
        },
        {
          label: 'Squats',
          data: squatsData,
          backgroundColor: 'rgba(153, 102, 255, 0.2)',
          borderColor: 'rgba(153, 102, 255, 1)',
          borderWidth: 1
        },
        {
          label: 'Average Squats',
          data: squatsAverageData,
          backgroundColor: 'rgba(153, 102, 255, 0.2)',
          borderColor: 'rgba(153, 102, 255, 1)',
          borderWidth: 1,
          type: 'line'
        }]
      },
      options: {
          scales: {
              xAxes: [{
                type: 'time',
                time: { unit: 'day' },
                ticks: { source: 'auto' }
              }],
              yAxes: [{
                  ticks: {
                      beginAtZero: true
                  }
              }]
          }
      }
    })
  }

  populateRunningGraph(data) {
    var distanceData = data.map((datum) => { return { x: datum.date, y: datum.distance } }).slice(0, 30).sort()
    var distanceAverageData = data.map((datum) => { return { x: datum.date, y: datum.distanceAverage } }).slice(0, 30).sort()
    var paceAverageData = data.map((datum) => { return { x: datum.date, y: datum.paceCalcAverage } }).slice(0, 30).sort()
    var speedAverageData = data.map((datum) => { return { x: datum.date, y: datum.speedAverage } }).slice(0, 30).sort()

    var chartElement = document.getElementById('runningCanvas')
    new Chart(chartElement, {
      type: 'bar',
      data: {
        datasets: [{
          label: 'Distance',
          data: distanceData,
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          borderColor: 'rgba(54, 162, 235, 1)',
          borderWidth: 1
        },
        {
          label: 'Average Distance',
          data: distanceAverageData,
          backgroundColor: 'rgba(54, 162, 235, 0.2)',
          borderColor: 'rgba(54, 162, 235, 1)',
          borderWidth: 1,
          type: 'line'
        },
        {
          label: 'Average Pace',
          data: paceAverageData,
          backgroundColor: 'rgba(255, 99, 132, 0)',
          borderColor: 'rgba(255, 99, 132, 1)',
          borderWidth: 1,
          type: 'line'
        },
        {
          label: 'Average Speed',
          data: speedAverageData,
          backgroundColor: 'rgba(75, 192, 192, 0)',
          borderColor: 'rgba(75, 192, 192, 1)',
          borderWidth: 1,
          type: 'line'
        }]
      },
      options: {
          scales: {
              xAxes: [{
                type: 'time',
                time: { unit: 'day' },
                ticks: { source: 'auto' }
              }],
              yAxes: [{
                  ticks: {
                      beginAtZero: true
                  }
              }]
          }
      }
    })
  }

  populateWeightGraph(data) {
    var pullupsAverageData = data.map((datum) => { return { x: datum.date, y: datum.weightAverage } }).slice(0, 180).sort()
    var bmiAverageData = data.map((datum) => { return { x: datum.date, y: datum.bmiAverage } }).slice(0, 180).sort()

    var chartElement = document.getElementById('weightCanvas')
    new Chart(chartElement, {
      type: 'bar',
      data: {
        datasets: [
        {
          label: 'Average Weight',
          data: pullupsAverageData,
          backgroundColor: 'rgba(54, 162, 235, 0)',
          borderColor: 'rgba(54, 162, 235, 1)',
          borderWidth: 1,
          pointRadius: 1,
          pointHoverRadius: 2,
          // pointStyle: 'line',
          type: 'line',
          yAxisID: 'weight'
        },
        {
          label: 'Average BMI',
          data: bmiAverageData,
          backgroundColor: 'rgba(255, 99, 132, 0)',
          borderColor: 'rgba(255, 99, 132, 1)',
          borderWidth: 1,
          pointRadius: 1,
          pointHoverRadius: 2,
          type: 'line',
          yAxisID: 'bmi'
        }]
      },
      options: {
          scales: {
              xAxes: [{
                type: 'time',
                time: { unit: 'day' },
                ticks: { source: 'auto' }
              }],
              yAxes: [{
                id: 'weight',
                ticks: {
                    beginAtZero: true
                }
              },
              {
                id: 'bmi',
                position: 'right',
                ticks: {
                  beginAtZero: true
              }
            }]
          }
      }
    })
  }

  getDrinksRecommendation() {
    fetch(`${apiRoot}/Drinks?recommend=1`, { headers: { "x-api-key": apiKey } })
    .then(res => { return res.json() })
    .then((data) => {
      var drinkSpan = document.getElementById('spnNextDrink')
      drinkSpan.innerText = (data.allowedDrinks > 0) 
        ? ` (but only ${data.allowedDrinks} today)`
        : ` (but not for ${data.dryDays} days)`
    })
  }

  getExerciseRecommendation() {
    fetch(`${apiRoot}/Exercise?recommend=1`, { headers: { "x-api-key": apiKey } })
    .then(res => { return res.json() })
    .then((data) => {
      var drinkSpan = document.getElementById('spnNextExercise')
      drinkSpan.innerText = ` (${data.pullups} Pullups | ${data.pushups} Pushups | ${data.situps} Situps | ${data.squats} Squats)`
    })
  }

  componentDidMount() {
    this.getData('Drinks', true, this.populateDrinksGraph)
    this.getData('Exercise', true, this.populateExerciseGraph)
    this.getData('Running', true, this.populateRunningGraph)
    this.getData('Weight', true, this.populateWeightGraph)
    this.getDrinksRecommendation()
    this.getExerciseRecommendation()
  }    

  render() {
    return (
      <div className="App">
        <header className="App-header">
          <p>
            <img src={logo} alt="logo" height="100px" onClick={ this.handleClick } />
            The website of James. Let's look at some charts!
          </p>
        </header>
        <div className="div-spacer"></div>
        <div className="div-canvas">
            James Be Drankin' <span id="spnNextDrink" />
            <canvas id="drinksCanvas"></canvas>
        </div>
        <div className="div-spacer"></div>
        <div className="div-canvas">
          James Be Liftin' Bro <span id="spnNextExercise" />
          <canvas id="exerciseCanvas"></canvas>
        </div>
        <div className="div-spacer"></div>
        <div className="div-canvas">
          James Be Huffin' and Puffin'
          <canvas id="runningCanvas"></canvas>
        </div>
        <div className="div-spacer"></div>
        <div className="div-canvas">
          James Be Svelte
          <canvas id="weightCanvas"></canvas>
        </div>
        <footer className="App-footer">
          <a
            className="App-link"
            href="https://reactjs.org"
            target="_blank"
            rel="noopener noreferrer"
          >
            Learn React
          </a>
        </footer>
      </div>
    );
  }
}

export default App;
